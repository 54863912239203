import React from 'react';

const IconThemeDark = () => (
    <svg
        className="icon icon-gradient"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM10.4544 4.08082C10.3622 4.05973 10.2665 4.05981 10.1744 4.08106C8.76097 4.40711 7.46056 5.10558 6.40821 6.10396C5.35586 7.10233 4.58995 8.36419 4.18999 9.75854C3.79003 11.1529 3.77061 12.6289 4.13374 14.0333C4.49687 15.4377 5.22931 16.7193 6.25503 17.745C7.28074 18.7707 8.56232 19.5031 9.96671 19.8663C11.3711 20.2294 12.8471 20.21 14.2415 19.81C15.6358 19.4101 16.8977 18.6442 17.896 17.5918C18.8944 16.5395 19.5929 15.2391 19.919 13.8256C19.9387 13.74 19.9402 13.6512 19.9233 13.565L19.9233 13.5649C19.8907 13.3988 19.7922 13.253 19.6503 13.1608C19.5084 13.0687 19.3351 13.038 19.1702 13.0758C18.0341 13.3364 16.8504 13.3042 15.7301 12.9821C14.6099 12.66 13.5898 12.0587 12.7656 11.2344C11.9414 10.4102 11.34 9.39013 11.0179 8.26989C10.6958 7.14964 10.6636 5.96594 10.9242 4.82982C10.9613 4.66821 10.9326 4.49849 10.8445 4.35802C10.7564 4.21755 10.6161 4.11784 10.4545 4.08085L10.4544 4.08082ZM7.26853 7.01079C7.92604 6.38701 8.69799 5.90158 9.53838 5.57922C9.43918 6.59663 9.53241 7.62686 9.81659 8.61528C10.1972 9.93901 10.9078 11.1444 11.8817 12.1183C12.8556 13.0923 14.061 13.8028 15.3847 14.1834C16.3732 14.4676 17.4034 14.5608 18.4208 14.4616C18.0984 15.302 17.613 16.074 16.9892 16.7315C16.1444 17.622 15.0767 18.2701 13.8968 18.6085C12.7169 18.9469 11.468 18.9633 10.2796 18.6561C9.09128 18.3488 8.00684 17.729 7.13891 16.8611C6.27098 15.9932 5.65121 14.9087 5.34394 13.7204C5.03666 12.532 5.0531 11.2831 5.39153 10.1032C5.72997 8.92334 6.37807 7.85558 7.26853 7.01079Z"
        />
    </svg>
);

export default IconThemeDark;
