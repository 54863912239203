import React from 'react';

const IconLinkedIn = () => (
    <svg
        className="icon icon-gradient"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19.9211 19.9214H16.5873V14.7004C16.5873 13.4555 16.565 11.8528 14.8533 11.8528C13.117 11.8528 12.8513 13.2092 12.8513 14.6098V19.9211H9.51754V9.18463H12.718V10.6519H12.7628C13.4154 9.53606 14.6288 8.86962 15.9205 8.91757C19.2995 8.91757 19.9225 11.1402 19.9225 14.0316L19.9211 19.9214ZM5.7559 7.71703C4.68743 7.71723 3.8211 6.85119 3.82091 5.78269C3.82072 4.7142 4.68673 3.84785 5.75521 3.84766C6.82368 3.84747 7.69001 4.7135 7.6902 5.782C7.69029 6.29511 7.48655 6.78724 7.1238 7.15013C6.76105 7.51302 6.269 7.71694 5.7559 7.71703ZM7.4228 19.9214H4.08553V9.18463H7.4228V19.9214ZM21.5831 0.751678H2.4103C1.50415 0.741452 0.761059 1.46731 0.75 2.37347V21.6262C0.76068 22.5328 1.50372 23.2594 2.4103 23.2499H21.5831C22.4915 23.2611 23.2374 22.5346 23.25 21.6262V2.37208C23.237 1.46413 22.491 0.738314 21.5831 0.750143" />
    </svg>
);

export default IconLinkedIn;
